@tailwind base;
@tailwind components;
@tailwind utilities;
/* 
:root {
  --tw-ring-color: #2F7B80;
} */

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

select {
  -webkit-appearance:none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* 
input[type="range"]::-moz-range-track {
  background: repeating-linear-gradient(to right, 
    #ccc, 
    #ccc 10%, 
    #000 10%, 
    #000 11%, 
    #ccc 11%, 
    #ccc 20%);
} */


.line {
  fill:none;
  /* stroke: tomato; */
  stroke-width: 1;
}


.line-yellow {
  stroke: rgb(193, 177, 48);
}

.line-red {
  stroke: #f15459;
}

.line-blue{
  stroke: #339CA2;
}

li {
  padding: 0 !important;
}

tr, table {
  border: 0;
}

td {
  padding: 0rem;
}

td {
  line-height: 1.4;
}